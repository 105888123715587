import {ModuleWithProviders, NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {RouterLink} from '@angular/router'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {ModalModule} from 'ngx-bootstrap/modal'

import {SidebarComponent} from './components/sidebar/sidebar.component'
import {HeaderComponent} from './components/header/header.component'
import {HeaderNavTabsComponent} from './components/header/nav-tabs/header-nav-tabs.component'
import {HeaderProfileComponent} from './components/header/profile/header-profile.component'
import {AlertsComponent} from './components/alerts/alerts.component'
import {DialogComponent} from './components/dialog/dialog.component'
import {MasterGuard} from './guards/master.guard'
import {AuthGuard} from './guards/auth.guard'
import {RoleGuard} from './guards/role.guard'
import {RequestInterceptor} from './interceptors/request.interceptor'

import {HasRolesPipe} from '@shared/pipes/has-roles.pipe'
import {BgImageDirective} from '@shared/directives/bg-image.directive'
import {ConfirmModalComponent} from '@core/components/confirm-modal/confirm-modal.component'
import {ModalComponent} from '@shared/components/modal/modal.component'
import {SafeHtmlPipe} from '@shared/pipes/safe-html.pipe'

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterLink,
    FontAwesomeModule,
    ModalModule.forRoot(),
    HasRolesPipe,
    BgImageDirective,
    ModalComponent,
    SafeHtmlPipe,
  ],
  declarations: [
    SidebarComponent,
    HeaderComponent,
    HeaderNavTabsComponent,
    HeaderProfileComponent,
    AlertsComponent,
    DialogComponent,
    ConfirmModalComponent,
  ],
  exports: [
    SidebarComponent,
    AlertsComponent,
    HeaderComponent,
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RequestInterceptor,
          multi: true,
        },
        MasterGuard,
        AuthGuard,
        RoleGuard,
      ],
    }
  }
}
