import {Component} from '@angular/core'
import {map, Observable} from 'rxjs'

import {AuthService} from '@core/services/http/auth.service'
import {UserService} from '@core/services/http/user.service'
import {getRoleLabel} from '@core/constants/role-labels'

@Component({
  selector: 'header-profile',
  templateUrl: './header-profile.component.html',
  styleUrls: ['./header-profile.component.scss'],
})
export class HeaderProfileComponent {

  constructor(
    public authService: AuthService,
    public userService: UserService,
  ) {
  }

  get fullName$(): Observable<string> {
    return this.userService.user$.pipe(
      map(user => {
        if (!user) return null
        const staff = user.staff_member_profile
        return `${staff.first_name} ${staff.last_name}`
      }),
    )
  }

  get roles$(): Observable<string> {
    return this.userService.user$.pipe(
      map(user => {
        if (!user) return null
        return user.staff_member_profile.roles.map(r => getRoleLabel(r.role)).join(', ')
      }),
    )
  }

  get showHeader$(): Observable<boolean> {
    return this.authService.hasUserData$
  }
}
