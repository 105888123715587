<div class="items">

	<ng-container *ngFor="let item of navTabs">

		<a
			class="item"
			*ngIf="item.roles | hasRoles | async"
			[class.selected]="isSelected(item.link)"
			[class.disabled]="item.disabled"
			[routerLink]="item.link"
		>

			<ng-container *ngIf="item.text">{{ item.text }}</ng-container>

			<ng-container
				*ngIf="item.template"
				[ngTemplateOutlet]="item.template"
				[ngTemplateOutletContext]="{item}"
			></ng-container>

		</a>

	</ng-container>

</div>
