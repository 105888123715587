import {LOCALE_ID, NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {fas} from '@fortawesome/free-solid-svg-icons'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {CoreModule} from './core/core.module'
import {registerLocaleData} from '@angular/common'
import localeRu from '@angular/common/locales/ru'
import moment from 'moment'
import 'moment/locale/ru'

registerLocaleData(localeRu)
moment.locale('ru')

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule.forRoot(),
    FontAwesomeModule,
  ],
  declarations: [
    AppComponent,
  ],
  bootstrap: [
    AppComponent,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'ru'},
  ],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas)
  }
}
