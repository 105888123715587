export const extractHttpErrorMessage = data => {
  if (data?.detail?.[0]?.msg) {
    return data?.detail?.[0]?.msg
  }

  if (!data || typeof data === 'string') {
    return data
  }

  if (Array.isArray(data)) {
    return extractHttpErrorMessage(data[0])
  }

  const objKeys = Object.keys(data)
  return extractHttpErrorMessage(data[objKeys[0]])
}
