import {Injectable} from '@angular/core'

import {SIDEBAR_COLLAPSED} from '@core/constants/local-storage'

@Injectable({
  providedIn: 'root',
})
export class SidebarService {

  get collapsed(): boolean {
    return localStorage.getItem(SIDEBAR_COLLAPSED) === '1'
  }

  init(): void {
    if (this.collapsed) {
      document.querySelector(':root').classList.add('sidebar-collapsed')
      document.getElementById('sidebar').classList.add('sidebar-collapsed')
    }
  }

  toggle(): void {
    document.querySelector(':root').classList.toggle('sidebar-collapsed')
    document.getElementById('sidebar').classList.toggle('sidebar-collapsed')
    localStorage.setItem(SIDEBAR_COLLAPSED, this.collapsed ? '0' : '1')
  }
}
